<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="490"
  >
    <ValidationObserver v-slot="{ passes }">
      <v-form @submit.prevent="passes(updatePassword)">
        <v-card>
          <v-card-title class="text-h5">
            Update Password
          </v-card-title>
          <v-card-text>
            <ValidationProvider
              v-slot="{ errors }"
              name="password"
              rules="required"
            >
              <v-text-field
                v-model="user.password"
                :error-messages="errors"
                :label="$t('messages.password')"
                required
                :append-icon="visibility ? 'mdi-eye' : 'mdi-eye-off'"
                :type="visibility ? 'text' : 'password'"
                autocomplete="new-password"
                @click:append="visibility = !visibility"
              />
            </ValidationProvider>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="$router.push({ name: 'dashboard.user' })"
            >
              {{ $t('action.cancel') }}
            </v-btn>
            <v-btn
              type="submit"
              color="primary darken-1"
            >
              Change Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import authHeader from '@/utils'

export default {
  name: 'UpdatePassword',
  data: () => ({
    dialog: true,
    visibility: false,
    user: {
      id: null,
      password: null
    }
  }),
  watch: {
    dialog (val) {
      if (val === false) {
        this.$router.push({ name: 'dashboard.user' })
      }
    }
  },
  created () {
    this.user.id = this.$route.params.id
  },
  methods: {
    updatePassword () {
      this.$http
        .put(`/users/update/${this.$route.params.id}/password`, { password: this.user.password }, { headers: authHeader() })
        .then(() => {
          this.$store.commit('setNotification', { color: 'success', message: this.$t('action.update_success') })
          this.$router.push({ name: 'dashboard.user' })
        })
        .catch(error => {
          this.loading = false
          this.displayError(error)
        })
    }
  }
}
</script>
